body{
  background-color: #e0dde8ff;
}

.text-white {
  color: white !important;
}

#btn-excel, #btn-print {
  float: right;
}

/*****Home Page*****/
#btnAddForm {
  position: fixed;
  float: right !important;
  margin-top: 37%;
  margin-right: 40px;
}

.my-fixed-item {
  position: fixed;
  min-height: 120px;
  width: 50px;
  text-align: center;
  word-wrap: break-word;
  background-color: aquamarine;
}

#btnFinalizar {
  float: right !important
}

#logo_full {
  margin-left: 20%;
  margin-top: 5%;
}

header {
  background-color: #245687 !important;
}

.MuiButton-containedPrimary{
  background-color: #245687 !important;
}

.header-print, .header-print-logo {
  display: none;
}

@media print
{    
    .no-print, .no-print *, .MuiBottomNavigation-root
    {
      display: none !important;
    }

    p, h1, h2, h3, h4, h5, h6, td, th {
      font-size: 12px !important;
      padding: 2px !important;
    }

    .header-print {
      text-align: center;
      display: block !important;
    }
    
    .header-print-logo {
      display: block !important;
    }

    #print-title {
      font-size: 20px;
      text-align: center !important; 
    }
}